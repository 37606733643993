import React, { useEffect, useRef, useState } from 'react'

import Slider from 'react-slick'

import { Look, Model } from '../types/api-types'
import CardModel from './CardModel'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { trackEvent } from '../utils/tracking'
import CardMore from './CardMore'
import { useAppSelector } from '../services/store/store'
import { DEFAULT_NB_MAX_CAROUSEL_CARDS } from '../utils/constant'

interface ArrowProps {
    className: string
    onClick?(): void
    style: React.CSSProperties
}

interface CarouselModelProps {
    models: Model[]
    look: Look
    itemType: string
}

const CarouselModel = (props: CarouselModelProps) => {
    const { models, look, itemType } = props

    const config = useAppSelector((state) => state.config.apiConfig)

    const nbMaxCarouselCards = config?.sm_nb_max_cards || DEFAULT_NB_MAX_CAROUSEL_CARDS

    const [nbSlideShow, setNbSlideShow] = useState<number>(nbMaxCarouselCards)

    const slider: any = useRef()
    const swipeTrackingEvent: any = useRef()

    const CarouselNextArrow = (arrowProps: ArrowProps) => {
        const { onClick } = arrowProps

        if (!onClick) {
            return
        }

        const handleClick = () => {
            swipeTrackingEvent.current = {
                name: 'Swipe right',
                properties: { swipe_from: 'arrow' },
                category: 'Modal',
            }
            onClick()
        }

        return (
            <div className={`carousel--arrow carousel--right`} onClick={handleClick}>
                <RightOutlined />
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps: ArrowProps) => {
        const { onClick } = arrowProps

        if (!onClick) {
            return
        }

        const handleClick = () => {
            swipeTrackingEvent.current = {
                name: 'Swipe left',
                properties: { swipe_from: 'arrow' },
                category: 'Modal',
            }
            onClick()
        }

        return (
            <div className={`carousel--arrow carousel--left`} onClick={handleClick}>
                <LeftOutlined />
            </div>
        )
    }

    // ---- Setup resize listener for max width of image ----
    useEffect(() => {
        let interval = 0
        function handleResize() {
            if (models) {
                clearInterval(interval)

                // ---- We get the correct slideToShow according to window size ----
                let currentNbSlideShow = nbSlideShow
                switch (true) {
                    case window.innerWidth > 1024:
                        currentNbSlideShow = Math.min(nbMaxCarouselCards, models.length)
                        break
                    case window.innerWidth > 768:
                        currentNbSlideShow = Math.min(
                            Math.min(nbMaxCarouselCards, 3),
                            models.length
                        )
                        break
                    default:
                        currentNbSlideShow = 1
                }

                // ---- We calculate the max width of the carousel according to currentNbSlideShow and cardWidth ----
                if (nbSlideShow !== currentNbSlideShow) {
                    setNbSlideShow(currentNbSlideShow)
                }
            }
        }

        interval = window.setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [])

    const settings = {
        dots: nbSlideShow < models.length + (config?.enable_more_models ? 1 : 0),
        initialSlide: 0,
        infinite: false,
        slidesToShow: Math.min(nbMaxCarouselCards, models.length),
        slidesToScroll: Math.min(nbMaxCarouselCards, models.length),
        touchThreshold: 15,
        speed: 200,
        arrow: true,
        //@ts-ignore
        nextArrow: <CarouselNextArrow />,
        //@ts-ignore
        prevArrow: <CarouselPrevArrow />,
        onSwipe: (direction: string) => {
            const adaptedDirection = direction == 'right' ? 'left' : 'right'
            swipeTrackingEvent.current = {
                name: `Swipe ${adaptedDirection}`,
                properties: { swipe_from: 'image' },
                category: 'Modal',
            }
        },
        beforeChange: (current: number, next: number) => {
            // ---- Unfocus if we are focused in the more Card which caused bug on mobile ----
            ;(document.activeElement as HTMLElement)?.blur()

            // ---- Only if we moved ----
            if (current != next) {
                // ---- Check if the last slide is visible ---
                if (next + nbSlideShow === models.length + 1 && config?.enable_more_models) {
                    trackEvent(
                        `More models Visible`,
                        [
                            look,
                            { item_type: itemType.toUpperCase() },
                            swipeTrackingEvent.current
                                ? swipeTrackingEvent.current.properties
                                : null,
                        ],
                        'Modal'
                    )
                }

                // ---- Si on a un event a envoyer ----
                if (swipeTrackingEvent.current) {
                    trackEvent(
                        swipeTrackingEvent.current.name,
                        [
                            look,
                            next < models.length ? models[next] : null,
                            { item_type: itemType.toUpperCase() },
                            swipeTrackingEvent.current.properties,
                        ],
                        swipeTrackingEvent.current.category
                    )
                }
            }
            if (swipeTrackingEvent.current) {
                swipeTrackingEvent.current = null
            }
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: `16px`,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(Math.min(nbMaxCarouselCards, 3), models.length),
                    slidesToScroll: Math.min(Math.min(nbMaxCarouselCards, 3), models.length),
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: Math.min(nbMaxCarouselCards, models.length),
                    slidesToScroll: Math.min(nbMaxCarouselCards, models.length),
                },
            },
        ],
    }

    return (
        <div style={{ width: '100%', height: '100%' }} id='switchModelCarouselContainer'>
            <Slider
                ref={(slick) => (slider.current = slick)}
                {...settings}
                className={`carousel--container${
                    nbSlideShow >= models.length + (config?.enable_more_models ? 1 : 0)
                        ? ' carousel--container-no-swipe'
                        : ''
                }`}
            >
                {models.length > 0 &&
                    models.map((item, itemKey: number) => (
                        <CardModel
                            model={item}
                            look={look}
                            itemType={itemType}
                            key={itemKey}
                            enablePreview={true}
                        />
                    ))}
                {models.length > 0 && config?.enable_more_models && (
                    <CardMore models={models} onFinishedClick={() => slider.current.slickGoTo(0)} />
                )}
            </Slider>
        </div>
    )
}

export default CarouselModel
